import calendar from "../../../assets/img/index/howto/calendar.png"
import slack from "../../../assets/img/index/howto/slack.png"
import homework from "../../../assets/img/index/howto/homework.png"
import chat from "../../../assets/img/index/howto/chat.png"

export default [
  {
    title: "평상시 학습",
    image: calendar,
    description:
      "평상시에는 스터디스테이츠에서 공유드린 커리큘럼 문서를 보고 순차적으로 학습을 진행합니다.",
  },
  {
    title: "의견 교환",
    image: slack,
    description:
      "수시로 필요할때마다 슬랙을 통해 스터디 참여자들과 대화합니다.",
  },
  {
    title: "과제 제출",
    image: homework,
    description: "학습내용을 적용할 수 있는 과제들을 수행하고 제출합니다.",
  },
  {
    title: "온라인 스터디",
    image: chat,
    description: "정해진 시간에 온라인으로 모여 스터디를 진행합니다.",
  },
]
